import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import "./WebsiteCoordinatorsList.css";

const HapphoWebsiteCoordinator = () => {
    const [coordinators, setCoordinators] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchWithAuth = useFetchWithAuth();
    const navigate = useNavigate();

    const fetchCoordinators = async () => {
        try {
            const data = await fetchWithAuth("/api/happho-website-coordinator/");
            setCoordinators(data);
            setLoading(false);
        } catch (error) {
            handleApiError(error);
        }
    };

    const deleteCoordinator = async (id) => {
        try {
            await fetchWithAuth(`/api/happho-website-coordinator/${id}/`, { method: "DELETE" });
            setCoordinators(coordinators.filter((coordinator) => coordinator.id !== id));
        } catch (error) {
            console.error("Delete failed:", error);
            setError("Failed to delete coordinator.");
        }
    };

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this coordinator?")) {
            deleteCoordinator(id);
        }
    };

    const handleApiError = (error) => {
        if (error.response?.status === 401) {
            navigate("/login");
        } else if (error.request) {
            setError("Network error. Please check your connection and try again.");
        } else {
            setError("An unexpected error occurred. Please try again later.");
        }
        setLoading(false);
    };

    useEffect(() => {
        const token = localStorage.getItem("access_token");
        if (!token) {
            navigate("/login");
            return;
        }
        fetchCoordinators();
    }, [navigate]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error: {error}</p>;
    }

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">Website Coordinators List</h2>
            <Link to="/create-website-coordinator" className="btn btn-primary mb-3">
                Add New Coordinator
            </Link>
            {coordinators.length === 0 ? (
                <p>No coordinators found.</p>
            ) : (
                <table className="table table-bordered table-striped">
                    <thead>
                        <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Employee ID</th>
                            <th>Phone Number</th>
                            <th>Secret Key</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {coordinators.map((coordinator) => (
                            <tr key={coordinator.id}>
                                <td>{coordinator.happho_website_coordinator.username}</td>
                                <td>{coordinator.happho_website_coordinator.email}</td>
                                <td>{coordinator.happho_website_coordinator.emp_id}</td>
                                <td>{coordinator.happho_website_coordinator.phone_number}</td>
                                <td>{coordinator.happho_website_coordinator.secret_key}</td>
                                <td>
                                    <button
                                        className="btn btn-danger"
                                        onClick={() => handleDelete(coordinator.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default HapphoWebsiteCoordinator;
