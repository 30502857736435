import { getMessaging, getToken } from 'firebase/messaging';
const messaging = getMessaging();



export const initializeServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    console.log("ajakldjjll")
    navigator.serviceWorker.register('/firebase-messaging-sw.js', { scope: '/' })
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed badlyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy:', error);
      });
  }
};

export const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging);
      console.log('FCM Token:', token);
      // Send this token to your server to register the device
    } else {
      console.error('Notification permission denied.');
    }
  } catch (error) {
    console.error('Error getting permission or token:', error);
  }
};


