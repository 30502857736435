import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import fetchWithAuth from './apiService'; // Import the fetchWithAuth function

const firebaseConfig = {
  apiKey: "AIzaSyAxBkLAaYXUlXjLWXCtIxxvLPtBgaHN_ow",
  authDomain: "maintainence-app-9a71c.firebaseapp.com",
  projectId: "maintainence-app-9a71c",
  storageBucket: "maintainence-app-9a71c.appspot.com",
  messagingSenderId: "1001925003602",
  appId: "1:1001925003602:web:f22bedc911a5d9e74d928b",
  measurementId: "G-GYL1CTX7P8"
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

const getAccessToken = async () => {
  let token = localStorage.getItem("access_token");

  if (!token) {
    const refreshToken = localStorage.getItem("refresh_token");
    try {
      const response = await fetchWithAuth('/token/refresh/', {
        method: 'POST',
        data: { refresh: refreshToken },
      });
      token = response.access;
      localStorage.setItem("access_token", token);
    } catch (error) {
      console.error("Token refresh failed:", error);
      localStorage.removeItem("access_token");
      localStorage.removeItem("refresh_token");
      localStorage.removeItem("user");
      throw error;
    }
  }

  return token;
};

// Function to request permission and get FCM token
export const requestPermission = async () => {
  let device_id = Cookies.get('device_id');
  if (!device_id) {
    device_id = uuidv4();
    Cookies.set('device_id', device_id);
  }
  const device_type = "web";  // Can customize this based on actual device type logic

  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      console.log("Notification permission granted.");
      const fcmToken = await getToken(messaging, {
        vapidKey: "BAlH1vpIzUTUjBfB_icyOvNuT0uJTrJHaft85ldknGjJMYa4fbqu_UQ1sF-_QR88CZhIOTSPwgo5rFr1X36lJlU"
      });
      if (fcmToken) {
        console.log('FCM Token:', fcmToken);
        // Call the function to register/update the device
        await registerOrUpdateDevice(fcmToken, device_id, device_type);
      } else {
        console.log('No registration token available.');
      }
    } else {
      console.error('Notification permission denied.');
    }
  } catch (error) {
    console.error('Error getting permission or token:', error);
  }
};

// Handle incoming messages when the app is in the foreground
export const handleForegroundMessages = (callback) => {
  return onMessage(messaging, (payload) => {
    console.log('Message received in foreground:', payload);
    callback(payload);
  });
};

// Function to register or update device
const registerOrUpdateDevice = async (fcmToken, device_id, device_type) => {
  try {
    const token = await getAccessToken();
    const response = await fetchWithAuth(`/api/notify/devices/?device_id=${device_id}`, {
      method: 'GET',
    });

    if (response.length > 0) {
      const existingDevice = response[0];
      console.log(response, "existingDevice");
      if (existingDevice.registration_id !== fcmToken) {
        await updateDeviceToken(fcmToken, device_id);
      }
    } else {
      await registerNewDevice(fcmToken, device_id, device_type);
    }
  } catch (error) {
    console.error('Error registering/updating device:', error);
    if (error.response && error.response.status === 404) {
      // Device not found, proceed with registration
      await registerNewDevice(fcmToken, device_id, device_type);
    }
  }
};

// Function to register new device
const registerNewDevice = async (fcmToken, device_id, device_type) => {
  try {
    const token = await getAccessToken();
    await fetchWithAuth('/api/notify/devices/', {
      method: 'POST',
      data: {
        registration_id: fcmToken,
        device_id: device_id,
        device_type: device_type
      },
    });
    console.log('New device registered successfully.');
  } catch (error) {
    console.error('Error registering new device:', error);
  }
};

// Function to update device token
const updateDeviceToken = async (fcmToken, device_id) => {
  try {
    const token = await getAccessToken();
    await fetchWithAuth('/api/notify/devices/', {
      method: 'PUT',
      data: {
        registration_id: fcmToken,
        device_id: device_id
      },
    });
    console.log('Device token updated successfully.');
  } catch (error) {
    console.error('Error updating device token:', error);
  }
};
