import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import "./Admins.css";

const Admin = () => {
    const [admins, setAdmins] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchWithAuth = useFetchWithAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAdminData = async () => {
            try {
                setIsLoading(true);
                const data = await fetchWithAuth("/api/website-admins/");
                setAdmins(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchAdminData();
    }, [fetchWithAuth]);

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this admin?")) {
            try {
                await fetchWithAuth(`/api/website-admins/${id}/`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                setAdmins(admins.filter((admin) => admin.website_admin.id !== id));
            } catch (error) {
                console.error("Error deleting admin:", error);
                setError("Failed to delete admin");
            }
        }
    };

    if (isLoading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">Error: {error}</div>;

    return (
        <div className="container">
            <h2 className="my-4">Admins List</h2>
            <Link to="/create-admin" className="btn btn-primary mb-4">
                Create New Admin
            </Link>
            <div className="table-responsive">
                <table id="admins-table" className="table table-bordered table-striped">
                    <thead className="thead-dark">
                        <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Emp ID</th>
                            <th>Phone Number</th>
                            <th>Secret Key</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {admins.length > 0 ? (
                            admins.map((admin) => (
                                <tr key={admin.website_admin.id}>
                                    <td>{admin.website_admin.username}</td>
                                    <td>{admin.website_admin.email}</td>
                                    <td>{admin.website_admin.emp_id}</td>
                                    <td>{admin.website_admin.phone_number}</td>
                                    <td>{admin.website_admin.secret_key}</td>
                                    <td>
                                        <button
                                            className="btn btn-danger"
                                            onClick={() => handleDelete(admin.website_admin.id)}
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6">No admins available.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Admin;
