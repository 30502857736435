import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Users.css";



const Users = () => {
    const [data, setData] = useState({
        clients: 0,
        ServiceProvider: 0,
        admins: 0,
        designers: 0,
        contractors: 0,
        procurement: 0,
        officeManagers: 0,
        officeAdmins: 0
    });


    return (
        <div className="center-content">
            <div className="container">
                <div className="row justify-content-center" style={{ display: "flex", flexWrap: "wrap" }}>
                    <div className="col-auto" style={{ flex: "0 0 auto", margin: "10px" }}>
                        <Link to="/clients" className="card-link">
                            <div className="card l-bg-green-dark">
                                <div className="card-body">
                                    <div className="card-icon">
                                        <i className="fas fa-users"></i>
                                    </div>
                                    <h5 className="card-title">Clients</h5>
                                    <h3 className="card-text">{data.clients}</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-auto" style={{ flex: "0 0 auto", margin: "10px" }}>
                        <Link to="/service-providers" className="card-link">
                            <div className="card l-bg-orange-dark">
                                <div className="card-body">
                                    <div className="card-icon">
                                        <i className="fas fa-users"></i>
                                    </div>
                                    <h5 className="card-title">Service Provider</h5>
                                    <h3 className="card-text">{data.ServiceProvider}</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-auto" style={{ flex: "0 0 auto", margin: "10px" }}>
                        <Link to="/admins" className="card-link">
                            <div className="card l-bg-cherry">
                                <div className="card-body">
                                    <div className="card-icon">
                                        <i className="fas fa-users"></i>
                                    </div>
                                    <h5 className="card-title">Admins</h5>
                                    <h3 className="card-text">{data.admins}</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-auto" style={{ flex: "0 0 auto", margin: "10px" }}>
                        <Link to="/happho-office-coordinator" className="card-link">
                            <div className="card l-bg-blue-dark">
                                <div className="card-body">
                                    <div className="card-icon">
                                        <i className="fas fa-users"></i>
                                    </div>
                                    <h5 className="card-title">Happho Website Coordinator</h5>
                                    <h3 className="card-text">{data.officeManagers}</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-auto" style={{ flex: "0 0 auto", margin: "10px" }}>
                        <Link to="/office-managers-page" className="card-link">
                            <div className="card l-bg-blue-dark">
                                <div className="card-body">
                                    <div className="card-icon">
                                        <i className="fas fa-users"></i>
                                    </div>
                                    <h5 className="card-title">Office Managers</h5>
                                    <h3 className="card-text">{data.officeManagers}</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-auto" style={{ flex: "0 0 auto", margin: "10px" }}>
                        <Link to="/office-admins-page" className="card-link">
                            <div className="card l-bg-purple-dark">
                                <div className="card-body">
                                    <div className="card-icon">
                                        <i className="fas fa-users"></i>
                                    </div>
                                    <h5 className="card-title">Office Admins</h5>
                                    <h3 className="card-text">{data.officeAdmins}</h3>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Users;
