import axios from 'axios';

const API_BASE_URL = "https://api.maintxpert.com";

const refreshToken = async () => {
  const refreshToken = localStorage.getItem("refresh_token");
  try {
    const response = await axios.post(`${API_BASE_URL}/token/refresh/`, 
      { refresh: refreshToken },
    );
    const newAccessToken = response.data.access;
    localStorage.setItem("access_token", newAccessToken);
    return newAccessToken;
  } catch (error) {
    throw error;
  }
};

const fetchWithAuth = async (url, options = {}) => {
  let token = localStorage.getItem("access_token");
  try {
    const response = await axios({
      ...options,
      url: `${API_BASE_URL}${url}`,
      headers: {
        ...options.headers,
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        // "ngrok-skip-browser-warning": "69420",
      },
      data: options.data,
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      try {
        token = await refreshToken();
        const response = await axios({
          ...options,
          url: `${API_BASE_URL}${url}`,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            // "ngrok-skip-browser-warning": "69420",
          },
          data: options.data,
        });
        return response.data;
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("user");
        throw refreshError;
      }
    } else {
      throw error;
    }
  }
};

export default fetchWithAuth;
