import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import "./AdminForm.css";



const AdminForm = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        username: "",
        email: "",
        emp_id: "",
        phone_number: ""
    });
    const [errors, setErrors] = useState({});
    const fetchWithAuth = useFetchWithAuth();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" }); // Clear the error for the current field
    };

const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
    }

    try {
        await fetchWithAuth("/api/website-admins/", {
            method: "POST",
            data: { website_admin: formData },
            headers: { "Content-Type": "application/json" }
        });
        console.log("Admin created successfully");
        navigate("/admins");
    } catch (error) {
        console.error("Error creating admin:", error);

        // Handle specific errors returned by the API
        if (error.response && error.response.data.website_admin) {
            const apiErrors = {};
            const apiErrorData = error.response.data.website_admin;

            if (apiErrorData.phone_number) {
                apiErrors.phone_number = apiErrorData.phone_number[0];
            }

            if (apiErrorData.email) {
                apiErrors.email = apiErrorData.email[0];
            }

            setErrors(apiErrors);
        } else {
            setErrors({ general: "An error occurred. Please try again." });
        }
    }
};


    const validateForm = (formData) => {
        const errors = {};

        if (!formData.username) {
            errors.username = "Username is required";
        }

        if (!formData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Invalid email address";
        }

        if (!formData.phone_number) {
            errors.phone_number = "Phone number is required";
        } else if (!/^\d{10}$/.test(formData.phone_number)) {
            errors.phone_number = "Invalid phone number";
        }

        return errors;
    };

    return (
        <div className="container">
            <h2 className="my-4 text-center">Create New Admin</h2>
            {errors.general && <div className="alert alert-danger">{errors.general}</div>}
            <div className="row justify-content-center">
                <div className="col-md-6">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                className={`form-control ${errors.username ? "is-invalid" : ""}`}
                                id="username"
                                name="username"
                                value={formData.username}
                                onChange={handleChange}
                                required
                            />
                            {errors.username && (
                                <div className="invalid-feedback">{errors.username}</div>
                            )}
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                                type="email"
                                className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
                        </div>
                        <div className="form-group">
                            <label htmlFor="emp_id">Employee ID</label>
                            <input
                                type="text"
                                className="form-control"
                                id="emp_id"
                                name="emp_id"
                                value={formData.emp_id}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone_number">Phone Number</label>
                            <input
                                type="text"
                                className={`form-control ${errors.phone_number ? "is-invalid" : ""}`}
                                id="phone_number"
                                name="phone_number"
                                value={formData.phone_number}
                                onChange={handleChange}
                                required
                            />
                            {errors.phone_number && (
                                <div className="invalid-feedback">{errors.phone_number}</div>
                            )}
                        </div>
                        <button type="submit" className="btn btn-primary btn-block">
                            Create Admin
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AdminForm;
