import React, { useEffect, useState } from "react";
import useFetchWithAuth from "../hooks/useFetchWithAuth";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [filteredNotifications, setFilteredNotifications] = useState([]);
  const [sortOrder, setSortOrder] = useState("recent");
  const [searchDate, setSearchDate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const fetchWithAuth = useFetchWithAuth();

  // Fetch notifications on component mount
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const data = await fetchWithAuth("/api/notify/notifications/");
        setNotifications(data);
        setFilteredNotifications(data);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotifications();
  }, [fetchWithAuth]);

  // Sort and filter notifications whenever notifications, sortOrder, or searchDate change
  useEffect(() => {
    const sortAndFilterNotifications = () => {
      let sorted = [...notifications];

      // Sort notifications by creation date
      sorted.sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return sortOrder === "recent" ? dateB - dateA : dateA - dateB;
      });

      // Filter by date if searchDate is provided
      if (searchDate) {
        const searchDateObj = new Date(searchDate);
        sorted = sorted.filter((notification) => {
          const notificationDate = new Date(notification.created_at);
          return notificationDate.toDateString() === searchDateObj.toDateString();
        });
      }

      setFilteredNotifications(sorted);
    };

    sortAndFilterNotifications();
  }, [notifications, sortOrder, searchDate]);

  // Function to handle marking a notification as read
  const markAsRead = async (notificationId) => {
    try {
      await fetchWithAuth(`/api/notify/notifications/${notificationId}/mark-read/`, {
        method: "POST",
      });

      // Update the notification in the local state
      setNotifications((prevNotifications) =>
        prevNotifications.map((notification) =>
          notification.id === notificationId
            ? { ...notification, is_read: true }
            : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  // Handle sort change
  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  // Handle search date change
  const handleSearchDateChange = (event) => {
    setSearchDate(event.target.value);
  };

  // Function to handle when a notification is clicked (opens modal and sets selected notification)
  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    setIsModalOpen(true);
    markAsRead(notification.id); // Mark as read when clicked
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedNotification(null);
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-6 bg-gray-100 shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Notifications</h2>

      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <div className="flex-1">
          <label htmlFor="sort" className="block mb-2 text-sm font-medium text-gray-700">
            Sort by:
          </label>
          <select
            id="sort"
            value={sortOrder}
            onChange={handleSortChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="recent">Most Recent</option>
            <option value="old">Oldest First</option>
          </select>
        </div>
        <div className="flex-1">
          <label htmlFor="searchDate" className="block mb-2 text-sm font-medium text-gray-700">
            Filter by Date:
          </label>
          <input
            type="date"
            id="searchDate"
            value={searchDate}
            onChange={handleSearchDateChange}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
      </div>

      {filteredNotifications.length > 0 ? (
        <ul className="space-y-4">
          {filteredNotifications.map((notification) => (
            <li
              key={notification.id}
              onClick={() => handleNotificationClick(notification)} // Show modal on click
              className={`p-4 border-l-4 rounded-lg cursor-pointer transition duration-200 ease-in-out ${
                notification.is_read
                  ? "border-gray-300 bg-gray-200"
                  : "border-blue-600 bg-white hover:bg-blue-50"
              }`}
            >
              <div className="flex justify-between items-center text-gray-800">
                <span className="font-medium">{notification.title}</span>
                <span className="text-sm text-gray-500">
                  {new Date(notification.created_at).toLocaleDateString()}
                </span>
              </div>
              <div className="text-gray-600">{notification.message}</div>
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-gray-500 text-center py-4">
          No notifications found for the selected criteria
        </p>
      )}

      {/* Modal for showing notification details */}
      {isModalOpen && selectedNotification && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
            <h3 className="text-xl font-bold mb-4">Notification Details</h3>
            <p><strong>Title:</strong> {selectedNotification.title}</p>
            <p><strong>Message:</strong> {selectedNotification.message}</p>
            {selectedNotification.client_info && (
              <div className="mt-2 text-gray-600 text-sm">
                <p><strong>Client Name:</strong> {selectedNotification.client_info.contact_person}</p>
                <p><strong>Company:</strong> {selectedNotification.client_info.company_name}</p>
                <p><strong>Email:</strong> {selectedNotification.client_info.email}</p>
                <p><strong>Phone:</strong> {selectedNotification.client_info.contact_number}</p>
                {/* Add any additional fields from client_info as needed */}
              </div>
            )}
            {/* Example of displaying extra data if it exists */}
            {selectedNotification.extra_data && (
              <div className="mt-2 text-gray-600 text-sm">
                <h4>Extra Information:</h4>
                <pre>{JSON.stringify(selectedNotification.extra_data, null, 2)}</pre>
              </div>
            )}
            <button
              onClick={handleCloseModal}
              className="mt-4 bg-blue-600 text-white px-4 py-2 rounded-md"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Notifications;
