import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SetPassword.css";

const SetPassword = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [secretKey, setSecretKey] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post("https://api.maintxpert.com/api/set-password/", {
                phone_number: phoneNumber,
                secret_key: secretKey,
                password: password
            });
            setMessage(response.data.success || "Password set successfully!");
            setTimeout(() => navigate("/login"), 2000);
        } catch (error) {
            console.error("Error setting password:", error);
            setMessage(error.response?.data?.error || "Failed to set password.");
        }
    };

    return (
        <div className="set-password-container">
            <div className="set-password-card">
                <h2 className="set-password-title">Set Your Password</h2>
                <form onSubmit={handleSubmit} className="set-password-form">
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                            type="text"
                            id="phoneNumber"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="secretKey">Secret Key</label>
                        <input
                            type="text"
                            id="secretKey"
                            value={secretKey}
                            onChange={(e) => setSecretKey(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">New Password</label>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="submit-btn">
                        Set Password
                    </button>
                </form>
                {message && (
                    <p
                        className={`message ${
                            message.includes("successfully") ? "success" : "error"
                        }`}
                    >
                        {message}
                    </p>
                )}
            </div>
        </div>
    );
};

export default SetPassword;
