import { Button, CircularProgress, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useFetchWithAuth from '../hooks/useFetchWithAuth';

const MaintenanceRequestDetails = () => {
  const [maintenanceRequest, setMaintenanceRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [serviceProviders, setServiceProviders] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState({});
  const [taskUpdates, setTaskUpdates] = useState({});
  const { maintenanceId } = useParams();
  const navigate = useNavigate();
  const fetchWithAuth = useFetchWithAuth();

  // Fetch maintenance request details
  const fetchMaintenanceRequest = useCallback(async () => {
    try {
      const response = await fetchWithAuth(`/client/maintenance-requests/${maintenanceId}/`);
      if (response.success) {
        const maintenanceData = response.data;
        setMaintenanceRequest(maintenanceData);

        // Pre-populate selected providers and status from fetched data
        const initialProviders = {};
        const initialTaskUpdates = {};
        maintenanceData.maintenance_tasks.forEach((task) => {
          if (task.service_provider) {
            initialProviders[task.id] = task.service_provider;
          }
          initialTaskUpdates[task.id] = {
            status: task.status,
          };
        });
        setSelectedProviders(initialProviders);
        setTaskUpdates(initialTaskUpdates);
      } else {
        setError(response.message || 'Failed to fetch maintenance request');
      }
    } catch (error) {
      handleApiError(error);
    } finally {
      setLoading(false);
    }
  }, [maintenanceId, fetchWithAuth]);

  // Fetch list of available service providers
  const fetchServiceProviders = useCallback(async () => {
    try {
      const data = await fetchWithAuth(`/api/service-providers/`);
      setServiceProviders(data);
    } catch (error) {
      console.error('Failed to fetch service providers:', error);
    }
  }, [fetchWithAuth]);

  useEffect(() => {
    fetchServiceProviders();
    fetchMaintenanceRequest();
  }, [fetchServiceProviders, fetchMaintenanceRequest]);

  // Error handling for API requests
  const handleApiError = (error) => {
    console.error('API Error:', error);
    setError('An error occurred while fetching the maintenance request. Please try again.');
  };

  // Handle service provider selection for each task
  const handleProviderChange = (taskId, e) => {
    setSelectedProviders((prev) => ({
      ...prev,
      [taskId]: e.target.value,
    }));
  };

  // Handle status and other task changes
  const handleTaskChange = (taskId, field, value) => {
    setTaskUpdates((prev) => ({
      ...prev,
      [taskId]: {
        ...prev[taskId],
        [field]: value,
      },
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);

    try {
      const updateData = maintenanceRequest.maintenance_tasks.map((task) => ({
        id: task.uuid,
        scheduled_date: taskUpdates[task.id]?.scheduled_date || task.scheduled_date,
        scheduled_time: taskUpdates[task.id]?.scheduled_time || task.scheduled_time,
        service_provider: selectedProviders[task.id] || null,
        status: taskUpdates[task.id]?.status || task.status,
      }));
      console.log(updateData, "updateDataupdateDataupdateDataupdateDataupdateDataupdateData");

      const response = await fetchWithAuth(`/client/schedule-maintenance/${maintenanceId}/`, {
        method: 'PATCH',
        data: { tasks: updateData },
        headers: { 'Content-Type': 'application/json' },
      });

      if (response) {
        alert('Maintenance request updated successfully');
        navigate('/website-coordinator/pending-requests/');
      } else {
        setError(response.message || 'Failed to update maintenance request');
      }
    } catch (error) {
      console.error('API Error:', error);
      handleApiError(error);
    } finally {
      setSubmitting(false);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-3xl font-semibold text-center mb-6">Maintenance Request Details</h2>
      
      {/* Back Button */}
      <Button variant="outlined" onClick={() => navigate(-1)} className="mb-4">
        Back
      </Button>

      <div className="space-y-4 mb-6">
        {/* Office Details Card */}
        <div className="bg-gray-100 p-4 rounded-md shadow">
          <h3 className="text-xl font-medium mb-2">Office Details</h3>
          <p><strong>Office Name:</strong> {maintenanceRequest?.office?.office_name || 'N/A'}</p>
          <p><strong>Office Address:</strong> {maintenanceRequest?.office?.address || 'N/A'}</p>
          <p><strong>City:</strong> {maintenanceRequest?.office?.city || 'N/A'}</p>
          <p><strong>State:</strong> {maintenanceRequest?.office?.state || 'N/A'}</p>
          <p><strong>Country:</strong> {maintenanceRequest?.office?.country || 'N/A'}</p>
        </div>

        {/* Client Details Card */}
        <div className="bg-gray-100 p-4 rounded-md shadow">
          <h3 className="text-xl font-medium mb-2">Client Details</h3>
          <p><strong>Requested By:</strong> {maintenanceRequest?.requested_by || 'N/A'}</p>
          <p><strong>Requested Date:</strong> {maintenanceRequest?.requested_date || 'N/A'}</p>
          <p><strong>Requested Time:</strong> {maintenanceRequest?.requested_time || 'N/A'}</p>
          <p><strong>Description:</strong> {maintenanceRequest?.description || 'N/A'}</p>
          <p><strong>Completed At:</strong> {maintenanceRequest?.completed_at || 'N/A'}</p>
          <p><strong>Major Issues:</strong> {maintenanceRequest?.major_issues || 'N/A'}</p>
        </div>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Category-wise task assignment */}
        {maintenanceRequest?.maintenance_tasks.map((task) => (
          <div key={task.id} className="space-y-4 mb-6 bg-gray-100 p-4 rounded-md shadow">
            <h3 className="text-xl font-medium mb-2">Task Category {task.category_name}</h3>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium mb-1">Scheduled Date</label>
                <input
                  type="date"
                  value={taskUpdates[task.id]?.scheduled_date || task.scheduled_date || ''}
                  onChange={(e) => handleTaskChange(task.id, 'scheduled_date', e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Scheduled Time</label>
                <input
                  type="time"
                  value={taskUpdates[task.id]?.scheduled_time || task.scheduled_time || ''}
                  onChange={(e) => handleTaskChange(task.id, 'scheduled_time', e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Assign Service Provider</label>
                <select
                  value={selectedProviders[task.id] || ''}
                  onChange={(e) => handleProviderChange(task.id, e)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">Select a service provider</option>
                  {serviceProviders.map((provider) => (
                    <option key={provider.service_provider} value={provider.service_provider.id}>
                      {provider.service_provider.username} ({provider.service_provider.email})
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Status</label>
                <select
                  value={taskUpdates[task.id]?.status || task.status || ''}
                  onChange={(e) => handleTaskChange(task.id, 'status', e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md"
                >
                  <option value="">Select status</option>
                  <option value="Pending">Pending</option>
                  <option value="In Progress">In Progress</option>
                  <option value="Completed">Completed</option>
                  <option value="Cancelled">Cancelled</option>
                </select>
              </div>
            </div>
          </div>
        ))}

        {/* Submit Button */}
        <Button type="submit" variant="contained" color="primary" disabled={submitting}>
          {submitting ? <CircularProgress size={24} /> : 'Update Maintenance Request'}
        </Button>
      </form>

      <div className="mt-6 p-4 bg-gray-100 rounded-md shadow">
        <h3 className="text-xl font-medium mb-2">Non-Editable Details</h3>
        <p><strong>Created At:</strong> {maintenanceRequest?.created_at || 'N/A'}</p>
        <p><strong>Last Updated At:</strong> {maintenanceRequest?.updated_at || 'N/A'}</p>
        <p><strong>Additional Info:</strong> {maintenanceRequest?.additional_info || 'N/A'}</p>
      </div>
    </div>
  );
};

export default MaintenanceRequestDetails;
