import React, { useCallback, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import useFetchWithAuth from "../hooks/useFetchWithAuth";

const PlanAMaintenanceList = () => {
  const [maintenanceRequests, setMaintenanceRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterText, setFilterText] = useState('');
  const fetchWithAuth = useFetchWithAuth();
  const navigate = useNavigate();

  const fetchMaintenanceRequests = useCallback(async () => {
    try {
      const data = await fetchWithAuth("/client/coordinator/list-maintenance-request/");
      setMaintenanceRequests(data);
      setLoading(false);
    } catch (error) {
      setError(
        error.response
          ? `Failed to fetch maintenance requests. Status: ${error.response.status}`
          : error.request
          ? "Network error. Please check your connection and try again."
          : "An unexpected error occurred. Please try again later."
      );
      setLoading(false);
    }
  }, [fetchWithAuth]);

  useEffect(() => {
    fetchMaintenanceRequests();
  }, [fetchMaintenanceRequests, navigate]);

  const columns = [
    {
      name: 'Office',
      selector: row => row.office.office_name,
      sortable: true,
    },
    {
      name: 'Description',
      selector: row => row.description,
      sortable: true,
      cell: row => `${row.description.substring(0, 50)}...`
    },
    {
      name: 'Requested By',
      selector: row => row.requested_by,
      sortable: true,
    },
    {
      name: 'Requested Date',
      selector: row => row.requested_date,
      sortable: true,
      cell: row => new Date(row.requested_date).toLocaleDateString()
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      cell: row => (
        <span className={`px-2 py-1 rounded-full text-xs font-semibold ${getStatusColor(row.status)}`}>
          {row.status}
        </span>
      )
    },
    {
      name: 'Scheduled Date',
      selector: row => row.scheduled_date,
      sortable: true,
      cell: row => row.scheduled_date ? new Date(row.scheduled_date).toLocaleDateString() : "Not scheduled"
    },
    {
      name: 'Maintenance Tasks',
      selector: row => row.maintenance_tasks,
      sortable: true,
      cell: row => (
        <div>
          {row.maintenance_tasks.map(task => task.category_name).join(', ')}
        </div>
      )
    },
    {
      name: 'Action',
      cell: row => (
        <Link to={`/maintenance-details/${row.uuid}`} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
          Details
        </Link>
      ),
      ignoreRowClick: true,
      button: true,
    }
  ];

  const filteredItems = maintenanceRequests.filter(item => {
    return (
      item.office.office_name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.description.toLowerCase().includes(filterText.toLowerCase()) ||
      item.requested_by.toLowerCase().includes(filterText.toLowerCase()) ||
      item.status.toLowerCase().includes(filterText.toLowerCase()) ||
      item.maintenance_tasks.some(task => 
        task.category_name.toLowerCase().includes(filterText.toLowerCase())
      )
    );
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <input
        type="text"
        placeholder="Search..."
        className="w-1/4 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        value={filterText}
        onChange={e => setFilterText(e.target.value)}
      />
    );
  }, [filterText]);

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case 'pending':
        return 'bg-yellow-200 text-yellow-800';
      case 'in progress':
        return 'bg-blue-200 text-blue-800';
      case 'completed':
        return 'bg-green-200 text-green-800';
      default:
        return 'bg-gray-200 text-gray-800';
    }
  };

  if (loading) return <p className="text-center text-gray-600">Loading...</p>;
  if (error) return <p className="text-red-500 text-center">{error}</p>;

  return (
    <div className="p-6 max-w-7xl mx-auto">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Plan A Maintenance Requests</h2>
      <div className="bg-white shadow-md rounded-lg p-6">
        <DataTable
          columns={columns}
          data={filteredItems}
          pagination
          highlightOnHover
          striped
          responsive
          subHeaderAlign="left"
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
          fixedHeader
          fixedHeaderScrollHeight="calc(100vh - 300px)"
          className="table-auto"
        />
      </div>
    </div>
  );
};

export default PlanAMaintenanceList;