import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import "./CoordinatorSidebar.css";

const CoordinatorSidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const user_data = JSON.parse(localStorage.getItem("user"));
    const fetchWithAuth = useFetchWithAuth();
    const [unreadCount, setUnreadCount] = useState(0);
    const [duePAMCount, setDuePAMCount] = useState(0);

    // Fetch notifications and count unread notifications
    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const notifications = await fetchWithAuth("/api/notify/notifications/");
                const unread = notifications.filter(notification => !notification.is_read).length;
                setUnreadCount(unread);
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        };

        const fetchPAMCount = async () => {
            try {
                const pamData = await fetchWithAuth("/client/");
                const due = pamData.filter(duePAM => duePAM.status !== "Completed").length;
                setDuePAMCount(due);
            } catch (error) {
                console.error("Error fetching PAM count:", error);
            }
        };

        fetchNotifications();
        fetchPAMCount();

        // Cleanup function to reset state if component unmounts
        return () => {
            setUnreadCount(0);
            setDuePAMCount(0);
        };
    }, [fetchWithAuth]);

    const handleLogout = async () => {
        try {
            const refreshToken = localStorage.getItem("refresh_token");

            // Clear tokens from local storage
            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("user");

            // Perform the logout request
            await fetch("/api/logout/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ refresh_token: refreshToken }),
            });

            // Redirect to the login page after logout
            navigate("/login");
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    return (
        <div id="sidebar" className="coordinator-sidebar">
            <div className="sidebar-header">
                <h3>Coordinator</h3>
            </div>
            <nav className="sidebar-menu">
                <ul>
                    <li className={`menu-item ${location.pathname === '/website-coordinator/pending-requests/' ? 'active' : ''}`}>
                        <Link to="/website-coordinator/pending-requests/">
                            <i className="fas fa-tasks"></i> <span>Pending Work Requests</span>
                        </Link>
                    </li>
                    <li className={`menu-item ${location.pathname === '/website-coordinator/approved-requests/' ? 'active' : ''}`}>
                        <Link to="/website-coordinator/approved-requests/">
                            <i className="fas fa-check-circle"></i> <span>Completed Work Requests</span>
                        </Link>
                    </li>
                    <li className={`menu-item ${location.pathname === '/website-coordinator/rejected-requests/' ? 'active' : ''}`}>
                        <Link to="/website-coordinator/rejected-requests/">
                            <i className="fas fa-times-circle"></i> <span>Rejected Work Requests</span>
                        </Link>
                    </li>
                    <li className={`menu-item ${location.pathname === '/notifications/' ? 'active' : ''}`}>
                        <Link to="/notifications/">
                            <i className="bx bxs-bell"></i>
                            <span>Notifications</span>
                            {unreadCount > 0 && (
                                <span className="notification-badge">{unreadCount}</span>
                            )}
                        </Link>
                    </li>
                    <li className={`menu-item ${location.pathname === '/plan-a-maintenance/' ? 'active' : ''}`}>
                        <Link to="/plan-a-maintenance/">
                            <i className="bx bxs-bell"></i>
                            <span>Plan A Maintenance</span>
                            {duePAMCount > 0 && (
                                <span className="notification-badge">{duePAMCount}</span>
                            )}
                        </Link>
                    </li>
                </ul>
            </nav>
            <div className="user-profile">
                <div className="user-info">
                    <p className="user-name">{user_data?.username}</p>
                    <p className="user-role">{user_data?.user_category}</p>
                    <p className="user-role">{user_data?.phone_number}</p>
                </div>
            </div>
            <div className="logout-btn">
                <button onClick={handleLogout} className="logout-button">
                    <i className="bx bx-log-out"></i>
                    <span>Logout</span>
                </button>
            </div>
        </div>
    );
};

export default CoordinatorSidebar;
