import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import "./ServiceProvider.css";

const ServiceProvider = () => {
    const [serviceProviders, setServiceProviders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const fetchWithAuth = useFetchWithAuth();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchServiceProviders = async () => {
            try {
                setIsLoading(true);
                const data = await fetchWithAuth("/api/service-providers/");
                const formattedData = data.map((provider) => ({
                    id: provider.id,
                    username: provider.service_provider.username,
                    email: provider.service_provider.email,
                    emp_id: provider.service_provider.emp_id,
                    phone_number: provider.service_provider.phone_number,
                    secret_key: provider.service_provider.secret_key,
                    service_provider_category_id: provider.service_provider_category_id,
                    service_provider_category_name: provider.service_provider_category_name
                }));
                setServiceProviders(formattedData);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchServiceProviders();
    }, [fetchWithAuth]);

    const handleDelete = async (id) => {
        if (window.confirm("Are you sure you want to delete this service provider?")) {
            try {
                await fetchWithAuth(`/api/service-providers/${id}/`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                setServiceProviders(serviceProviders.filter((provider) => provider.id !== id));
            } catch (error) {
                console.error("Failed to delete service provider:", error);
                setError("Failed to delete service provider");
            }
        }
    };

    if (isLoading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error">Error: {error}</div>;

    return (
        <div className="container">
            <h2 className="my-4">Service Provider List</h2>
            <Link to="/create-service-provider" className="btn btn-primary mb-4">
                Create New Service Provider
            </Link>
            <div className="table-responsive">
                <table
                    id="service-providers-table"
                    className="table table-bordered table-striped table-hover"
                >
                    <thead className="thead-dark">
                        <tr>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Employee ID</th>
                            <th>Phone Number</th>
                            <th>Secret Key</th>
                            <th>Category Name</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {serviceProviders.length > 0 ? (
                            serviceProviders.map((provider) => (
                                <tr key={provider.id}>
                                    <td>{provider.username}</td>
                                    <td>{provider.email}</td>
                                    <td>{provider.emp_id}</td>
                                    <td>{provider.phone_number}</td>
                                    <td>{provider.secret_key}</td>
                                    <td>{provider.service_provider_category_name}</td>
                                    <td>
                                        <div className="btn-group" role="group">
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => handleDelete(provider.id)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7">No Service Providers available.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ServiceProvider;
