import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Clients.css";

// Function to simulate fetching data from an API
const fetchClients = async () => {
    try {
        const response = await fetch("https://api.maintxpert.com/clients/");
        if (!response.ok) {
            throw new Error("Network response was not ok");
        }
        const clients = await response.json();
        return clients;
    } catch (error) {
        console.error("There has been a problem with your fetch operation:", error);
        return [];
    }
};

const Clients = () => {
    const [clients, setClients] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const fetchedClients = await fetchClients();
            setClients(fetchedClients);
        };

        fetchData();
    }, []);

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you want to delete this client?")) {
            // Here you would typically send a DELETE request to your API
            // For simplicity, this example does not implement deletion
            console.log(`Deleting client with ID ${id}`);
        }
    };

    return (
        <div className="container">
            <h2 className="my-4">Clients List</h2>
            <Link to="/create-client" className="btn btn-primary mb-4">
                Create New Client
            </Link>
            <div className="table-responsive">
                <table id="clients-table" className="table table-bordered table-striped">
                    <thead className="thead-dark">
                        <tr>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Actions</th>
                            <th>Security Key</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clients.length > 0 ? (
                            clients.map((client) => (
                                <tr key={client.id}>
                                    <td>{client.username}</td>
                                    <td>{client.phone_number}</td>
                                    <td>
                                        <div className="btn-group" role="group">
                                            <Link
                                                to={`/client-detail/${client.id}`}
                                                className="btn btn-info"
                                            >
                                                View
                                            </Link>
                                            <Link
                                                to={`/edit-client/${client.id}`}
                                                className="btn btn-warning"
                                            >
                                                Edit
                                            </Link>
                                            <button
                                                className="btn btn-danger"
                                                onClick={() => handleDelete(client.id)}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </td>
                                    <td>{client.security_key}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4">No clients available.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Clients;
