import axios from "axios";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const API_BASE_URL = "https://api.maintxpert.com";

const useFetchWithAuth = () => {
  const navigate = useNavigate();

  const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    try {
      const response = await axios.post(`${API_BASE_URL}/token/refresh/`,
        { refresh: refreshToken },
        // { headers: { "ngrok-skip-browser-warning": "69420" } }
      );
      const newAccessToken = response.data.access;
      localStorage.setItem("access_token", newAccessToken);
      return newAccessToken;
    } catch (error) {
      throw error;
    }
  };

  const fetchWithAuth = useCallback(async (url, options = {}) => {
    let token = localStorage.getItem("access_token");
    try {
      const response = await axios({
        ...options,
        url: `${API_BASE_URL}${url}`,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          // "ngrok-skip-browser-warning": "69420",
        },
        data: options.data,
      });
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        try {
          token = await refreshToken();
          const response = await axios({
            ...options,
            url: `${API_BASE_URL}${url}`,
            headers: {
              ...options.headers,
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              // "ngrok-skip-browser-warning": "69420",
            },
            data: options.data,
          });
          return response.data;
        } catch (refreshError) {
          console.error("Token refresh failed:", refreshError);
          localStorage.removeItem("access_token");
          localStorage.removeItem("refresh_token");
          localStorage.removeItem("user");
          navigate("/login");
          throw refreshError;
        }
      } else {
        throw error;
      }
    }
  }, [navigate]);

  return fetchWithAuth;
};

export default useFetchWithAuth;