// src/App.js

import React, { useEffect } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes, useLocation } from 'react-router-dom';
import './App.css';
import AdminForm from './components/AdminForm';
import Admins from './components/Admins';
import ApprovedWorkRequestsList from './components/ApprovedWorkRequestsList';
import Chat from "./components/Chat";
import Clients from './components/Clients';
import CoordinatorSidebar from './components/CoordinatorSidebar';
import EditServiceProvider from './components/EditServiceProvider';
import HapphoWebsiteCoordinator from './components/HapphoWebsiteCoordinator';
import HapphoWebsiteCoordinatorForm from './components/HapphoWebsiteCoordinatorForm';
import LoginPage from './components/LoginPage';
import Notifications from './components/Notifications';
import PlanAMaintenanceList from './components/PAM';
import PAMDetails from './components/PAMDetail';
import PendingWorkRequestsList from './components/PendingWorkRequestsList';
import RejectedWorkRequest from './components/RejectedWorkRequest';
import ServiceProvider from './components/ServiceProvider';
import ServiceProviderForm from './components/ServiceProviderForm';
import SetPassword from './components/SetPassword';
import Sidebar from './components/Sidebar';
import Users from './components/Users';
import WorkRequestDetails from './components/WorkRequestDetails';
import { handleForegroundMessages, requestPermission } from './firebase';

const AppContent = () => {
  const location = useLocation();
  const isSidebarVisible = location.pathname !== '/login' && location.pathname !== '/set-password';

  // Define paths where CoordinatorSidebar should be displayed
  const coordinatorPaths = [
    '/website-coordinator/pending-requests/',
    '/website-coordinator/approved-requests/',
    '/work-request-details/',
    '/website-coordinator/rejected-requests/',
    "/notifications/",
    '/work-request-details/:uuid',
    '/plan-a-maintenance',
    '/plan-a-maintenance/:uuid',
  ];

  const isCoordinatorSidebar = coordinatorPaths.some(path => {
    const regex = new RegExp(`^${path.replace(/:\w+/, '\\w+')}`);
    return regex.test(location.pathname);
  }) || location.pathname.startsWith('/work-request-chat/');

  useEffect(() => {
    requestPermission();
    const unsubscribe = handleForegroundMessages((payload) => {
      console.log('Received foreground message:', payload);
    });

    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  const user_data = JSON.parse(localStorage.getItem("user"));

  return (
    <div className='App'>
      {isSidebarVisible && (isCoordinatorSidebar ? <CoordinatorSidebar /> : <Sidebar />)}
      <div id='content'>
        <main>
          <Routes>
            <Route path='/' element={
              user_data ? (
                user_data.user_category === 'website_coordinator' ? (
                  <Navigate to='/website-coordinator/pending-requests/' />
                ) : (
                  <Navigate to='/users' />
                )
              ) : (
                <Navigate to='/login' />
              )
            } />
            <Route path='/users' element={<Users />} />
            <Route path='/clients' element={<Clients />} />
            <Route path='/service-providers' element={<ServiceProvider />} />
            <Route path='/create-service-provider' element={<ServiceProviderForm />} />
            <Route path='/admins' element={<Admins />} />
            <Route path='/create-admin' element={<AdminForm />} />
            <Route path='/edit-service-provider/:id' element={<EditServiceProvider />} />
            <Route path='/happho-office-coordinator' element={<HapphoWebsiteCoordinator />} />
            <Route path='/create-website-coordinator' element={<HapphoWebsiteCoordinatorForm />} />
            <Route path='/set-password' element={<SetPassword />} />
            <Route path='/login' element={<LoginPage />} />
            <Route path='/website-coordinator/pending-requests/' element={<PendingWorkRequestsList />} />
            <Route path='/website-coordinator/approved-requests/' element={<ApprovedWorkRequestsList />} />
            <Route path='/website-coordinator/rejected-requests/' element={<RejectedWorkRequest />} />
            <Route path='/work-request-details/:uuid' element={<WorkRequestDetails />} />
            <Route path="/notifications/" element={<Notifications />} />
            <Route path='/work-request-chat/:workRequestId' element={<Chat />} />
            <Route path='/plan-a-maintenance' element={<PlanAMaintenanceList />} />
            <Route path='/maintenance-details/:maintenanceId' element={<PAMDetails />} />
          </Routes>
        </main>
      </div>
    </div>
  );
};

const App = () => (
  <Router>
    <AppContent />
  </Router>
);

export default App;
