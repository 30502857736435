// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import App from './App';
// import './styles.css';
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );



// // src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { initializeServiceWorker, requestPermission } from './notifications';
import './styles.css';

// Initialize service worker and request permission for notifications
initializeServiceWorker();
requestPermission();

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
