import { MessageCircle, Send, X } from 'lucide-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import fetchWithAuth from '../apiService';

const Chat = () => {
  const { workRequestId } = useParams();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [workRequest, setWorkRequest] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const chatBoxRef = useRef(null);
  const lastMessageTimestampRef = useRef(null);
  const navigate = useNavigate();

  // Get current user data from localStorage
  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      try {
        const userObject = JSON.parse(userString);
        setCurrentUser(userObject);
      } catch (error) {
        console.error('Error parsing user data:', error);
        setError('Failed to load user data. Please try logging in again.');
      }
    }
  }, []);

  // Fetch work request details
  const fetchWorkRequestDetails = useCallback(async () => {
    try {
      const data = await fetchWithAuth(`/api/work-request/${workRequestId}/`);
      setWorkRequest(data);
    } catch (error) {
      console.error('Error fetching work request details:', error);
      setError('Failed to load work request details.');
    }
  }, [workRequestId]);

  // Fetch chat messages
  const fetchMessages = useCallback(async () => {
    try {
      const url = `/chat_api/work-request/${workRequestId}/chat/`;
      const params = lastMessageTimestampRef.current
        ? `?after=${encodeURIComponent(lastMessageTimestampRef.current)}`
        : '';
      const data = await fetchWithAuth(url + params, {
        method: 'GET',
      });
      console.log(data,"popopopopppppppppppppp")

      if (data.length > 0) {
        setMessages((prevMessages) => {
          const newMessages = data.filter(
            (msg) => !prevMessages.some((prevMsg) => prevMsg.id === msg.id)
          );
          const updatedMessages = [...prevMessages, ...newMessages].sort(
            (a, b) => new Date(a.timestamp) - new Date(b.timestamp)
          );
          if (updatedMessages.length > 0) {
            lastMessageTimestampRef.current = updatedMessages[updatedMessages.length - 1].timestamp;
          }
          return updatedMessages;
        });
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching messages:', error);
      setError(
        error.response
          ? `Failed to load messages. Status: ${error.response.status}`
          : 'An unexpected error occurred. Please try again later.'
      );
      setLoading(false);
    }
  }, [workRequestId]);

  // Scroll to the latest message when messages update
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  // Fetch messages at regular intervals
  useEffect(() => {
    fetchWorkRequestDetails();
    fetchMessages();
    const intervalId = setInterval(fetchMessages, 10000); // Fetch every 3 seconds
    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [fetchWorkRequestDetails, fetchMessages]);

  // Handle sending new messages
  const handleSendMessage = async () => {
    if (newMessage.trim() === '' || isSending) return;

    setIsSending(true);
    try {
      const data = await fetchWithAuth(`/chat_api/work-request/${workRequestId}/chat/send/`, {
        method: 'POST',
        data: { message: newMessage },
      });
      
      // Update messages without adding the new message (it will be fetched in the next polling cycle)
      setNewMessage('');
      
      // Trigger an immediate fetch to get the new message
      fetchMessages();
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Failed to send message.');
    } finally {
      setIsSending(false);
    }
  };

  // Group messages by date
  const groupMessagesByDate = useCallback((messages) => {
    const groupedMessages = [];
    let currentDate = null;

    messages.forEach((msg) => {
      const messageDate = new Date(msg.timestamp).toLocaleDateString();
      if (messageDate !== currentDate) {
        currentDate = messageDate;
        groupedMessages.push({ date: currentDate, messages: [msg] });
      } else {
        groupedMessages[groupedMessages.length - 1].messages.push(msg);
      }
    });

    return groupedMessages;
  }, []);

  const groupedMessages = groupMessagesByDate(messages);

  if (loading && messages.length === 0) {
    return <div className="flex items-center justify-center h-screen">Loading chat...</div>;
  }

  if (error) {
    return <div className="flex items-center justify-center h-screen text-red-500">{error}</div>;
  }

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <div className="flex-1 overflow-hidden">
        <div className="flex flex-col h-full">
          {/* Chat Header */}
          <div className="bg-white shadow-md p-4 flex justify-between items-center">
            <h1 className="text-xl font-bold text-gray-800">
              {workRequest ? `Chat with ${workRequest.client.contact_person}` : 'Chat'}
            </h1>
            <div className="flex">
              {/* Button to open the modal */}
              <button 
                onClick={() => setIsModalOpen(true)}
                className="text-blue-600 hover:text-blue-800 transition-colors mr-4"
              >
                View Request Details
              </button>
              <button 
                onClick={() => navigate(-1)}
                className="text-gray-600 hover:text-gray-800 transition-colors"
              >
                <X className="w-6 h-6" />
              </button>
            </div>
          </div>

          {/* Messages Area */}
          <div 
            ref={chatBoxRef}
            className="flex-1 overflow-y-auto p-4 space-y-4"
          >
            {groupedMessages.length > 0 ? (
              groupedMessages.map((group, index) => (
                <div key={`${group.date}-${index}`}>
                  <div className="text-center text-gray-500 my-2">{group.date}</div>
                  {group.messages.map((msg) => (
                    <div
                      key={msg.id}
                      className={`flex ${msg.sender === currentUser?.username ? 'justify-end' : 'justify-start'}`}
                    >
                      <div className={`max-w-xs lg:max-w-md xl:max-w-lg ${
                        msg.sender === currentUser?.username 
                          ? 'bg-blue-500 text-white rounded-l-lg rounded-br-lg' 
                          : 'bg-white text-gray-800 rounded-r-lg rounded-bl-lg'
                      } p-3 shadow`}
                      >
                        <p className="font-semibold text-sm">{msg.sender}</p>
                        <p className="text-sm mt-1">{msg.message}</p>
                        <p className="text-xs mt-1 opacity-75">
                          {new Date(msg.timestamp).toLocaleTimeString([], {
                            hour: '2-digit',
                            minute: '2-digit',
                          })}
                        </p>
                        {!msg.is_read && (
                          <span className="inline-block w-2 h-2 bg-green-400 rounded-full ml-2"></span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">No messages yet.</p>
            )}
          </div>

          {/* Input Area */}
          <div className="bg-white border-t border-gray-200 p-4">
            <div className="flex items-center">
              <input
                type="text"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                placeholder="Type a message..."
                disabled={isSending}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') handleSendMessage();
                }}
                className="flex-1 border border-gray-300 rounded-l-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                onClick={handleSendMessage}
                disabled={newMessage.trim() === '' || isSending}
                className={`bg-blue-500 text-white rounded-r-full p-2 ${
                  newMessage.trim() === '' || isSending 
                    ? 'opacity-50 cursor-not-allowed' 
                    : 'hover:bg-blue-600'
                } transition-colors`}
              >
                {isSending ? (
                  <MessageCircle className="w-6 h-6 animate-spin" />
                ) : (
                  <Send className="w-6 h-6" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal for Work Request Details */}
      {isModalOpen && workRequest && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full">
            <h2 className="text-xl font-bold mb-4">Work Request Details</h2>
            <p><strong>Client Name:</strong> {workRequest.client.contact_person}</p>
            <p><strong>Request ID:</strong> {workRequest.id}</p>
            <p><strong>Status:</strong> {workRequest.status}</p>
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;