import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./LoginPage.css";

const LoginPage = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!phoneNumber || !password) {
            setError("Please enter both phone number and password.");
            return;
        }
        try {
            const response = await axios.post(
                "https://api.maintxpert.com/api/login/",
                { phone_number: phoneNumber, password },
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true
                }
            );
            const { data } = response;
            localStorage.setItem("access_token", data.access);
            localStorage.setItem("refresh_token", data.refresh);
            localStorage.setItem("user", JSON.stringify(data.user));
            axios.defaults.headers.common["Authorization"] = `Bearer ${data.access}`;

            // Redirect based on user category
            if (data.user.user_category === "admin") {
                navigate("/users");
            } else if (data.user.user_category === "website_coordinator") {
                navigate("/website-coordinator/pending-requests/");
            } else {
                navigate("/");
            }
        } catch (error) {
            if (error.response) {
                console.error("Error response data:", error.response.data);
                console.error("Error response status:", error.response.status);
                console.error("Error response headers:", error.response.headers);
                setError(error.response.data.detail || "Invalid credentials or server error.");
            } else if (error.request) {
                console.error("Error request:", error.request);
                setError("No response from server.");
            } else {
                console.error("Error message:", error.message);
                setError("An unexpected error occurred.");
            }
            console.error("Error config:", error.config);
        }
    };

    return (
        <div className="login-container">
            <div className="login-form-wrapper">
                <h1 className="login-title">Welcome Back</h1>
                <p className="login-subtitle">Sign in to your account</p>
                {error && <p className="error-message">{error}</p>}
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <input
                            id="phoneNumber"
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                            placeholder="Enter your phone number"
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            placeholder="Enter your password"
                        />
                    </div>
                    <button type="submit" className="login-button">
                        Sign In
                    </button>
                </form>
                <p className="set-password-link">
                    <a href="/set-password">Set your password?</a>
                </p>
            </div>
        </div>
    );
};

export default LoginPage;
