import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const fetchServiceProvider = async (id) => {
    try {
        const response = await fetch(`https://api.maintxpert.com/api/service-providers/${id}/`);
        if (!response.ok) throw new Error(response.statusText);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch service provider:", error);
        return null;
    }
};

const fetchServiceProviderCategories = async () => {
    try {
        const response = await fetch(`https://api.maintxpert.com/api/service-category-options/`);
        if (!response.ok) throw new Error(response.statusText);
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch service provider categories:", error);
        return [];
    }
};

const updateServiceProvider = async (id, formData) => {
    try {
        const response = await fetch(`https://api.maintxpert.com/api/service-providers/${id}/`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(formData)
        });
        if (!response.ok) throw new Error(response.statusText);
        return await response.json();
    } catch (error) {
        console.error("Failed to update service provider:", error);
        throw error;
    }
};

const EditServiceProvider = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        service_provider: {
            username: "",
            phone_number: ""
        },
        service_provider_category_id: ""
    });
    const [serviceProviderCategories, setServiceProviderCategories] = useState([]);
    const [serviceProviderCategoryId, setServiceProviderCategoryId] = useState("");

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const loadServiceProvider = async () => {
            const data = await fetchServiceProvider(id);
            const categories = await fetchServiceProviderCategories();
            if (data && categories.length) {
                setFormData({
                    service_provider: {
                        username: data.service_provider.username,
                        phone_number: data.service_provider.phone_number
                    },
                    service_provider_category_id: data.service_provider_category_id
                });
                setServiceProviderCategories(categories);
                setIsLoading(false);
            } else {
                setError("Failed to load service provider");
                setIsLoading(false);
            }
        };
        loadServiceProvider();
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            if (name === "username" || name === "phone_number") {
                return {
                    ...prevData,
                    service_provider: {
                        ...prevData.service_provider,
                        [name]: value
                    }
                };
            }
            return {
                ...prevData,
                [name]: value
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await updateServiceProvider(id, formData);
            navigate("/service-providers");
        } catch (error) {
            setError("Failed to update service provider");
        }
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="container">
            <h2 className="my-4">Edit Service Provider</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        name="username"
                        value={formData.service_provider.username}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="phone_number">Phone Number</label>
                    <input
                        type="text"
                        className="form-control"
                        id="phone_number"
                        name="phone_number"
                        value={formData.service_provider.phone_number}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="service_provider_category_id">Category Name</label>
                    <select
                        className="form-control"
                        id="service_provider_category_id"
                        name="service_provider_category_id"
                        value={formData.service_provider_category_id}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select a category</option>
                        {serviceProviderCategories.map((category) => (
                            <option
                                key={category.service_provider_category_id}
                                value={category.service_provider_category_id}
                            >
                                {category.category_name}
                            </option>
                        ))}
                    </select>
                </div>
                <button type="submit" className="btn btn-primary">
                    Update Service Provider
                </button>
            </form>
        </div>
    );
};

export default EditServiceProvider;
