import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useFetchWithAuth from "../hooks/useFetchWithAuth";
import "./Sidebar.css";

const Sidebar = () => {
    const navigate = useNavigate();
    const fetchWithAuth = useFetchWithAuth();
    const user_data = JSON.parse(localStorage.getItem("user"));
    console.log(user_data, "User data:");

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token");
        const refreshToken = localStorage.getItem("refresh_token");

        if (!accessToken || !refreshToken) {
            navigate("/login");
        }
    }, [navigate]);

    const handleLogout = async () => {
        try {
            const refreshToken = localStorage.getItem("refresh_token");

            localStorage.removeItem("access_token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("user");

            await fetchWithAuth("/api/logout/", {
                method: "POST",
                data: { refresh_token: refreshToken },
            });

            navigate("/login");
        } catch (error) {
            console.error("Logout error:", error);
        }
    };

    return (
        <nav id="sidebar">
            <div className="sidebar-header">
                <h3>Admin Dashboard</h3>
            </div>
            <ul className="sidebar-menu">
                <li className="menu-item">
                    <Link to="/users">
                        <i className="bx bxs-user"></i>
                        <span>Users</span>
                    </Link>
                </li>
                {/* Add more menu items as needed */}
            </ul>

            <div className="user-profile p-4 border-t border-gray-300">
                <div className="user-info">
                    <p className="user-name font-semibold text-gray-900">{user_data?.username}</p>
                    <p className="user-phone text-gray-800">{user_data?.phone_number}</p>
                    <p className="user-role text-gray-700">{user_data?.user_category}</p>
                </div>
            </div>

            <div className="logout-btn">
                <button onClick={handleLogout}>
                    <i className="bx bx-log-out"></i>
                    <span>Logout</span>
                </button>
            </div>
        </nav>
    );
};

export default Sidebar;
